import segmentModule from '@/services/sessionFilter';
import staticSegmentModule from '@/services/staticSegment';
import customerServices from '../../../services/customer';
const _ = require('lodash');

export default {
  name: 'exportSegmentUsers',
  components: {},
  data() {
    return {
      dialogVisible: false,
      exportingSegment: false,

      exportEmailTo: null,
      fetchedUserPropertyList: false,
      userPropertyList: [],

      segmentType: 'dynamic',
      query: null,
      segmentId: null,
      columnsToExport: []
    };
  },
  methods: {
    async exportUsers() {
      // Validate Segment Name.
      if (this.columnsToExport.length == 0) {
        this.warningToast('Select atleast one customer attribute for export.');
        return;
      }
      this.exportingSegment = true;

      // Call api
      try {
        let params = {
          query: this.query,
          segmentId: this.segmentId,
          attributes: this.columnsToExport,
          email: this.exportEmailTo
        };

        if (this.segmentType == 'static') {
          await staticSegmentModule.exportStaticSegmentCustomersV1(params);
        } else {
          await segmentModule.exportSegmentUsersV1(params);
        }

        this.exportingSegment = false;

        // Show Success Dialog
        this.$swal({
          title: 'Export Started',
          text: 'You will receive an email with exported csv link.',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#67C23A',
          confirmButtonText: 'Continue',
          allowEscapeKey: false,
          allowOutsideClick: false
        });

        this.dialogVisible = false;
      } catch (err) {
        console.log(err);
        this.errorToast('Failed to export customers. Please contact support!');
        this.exportingSegment = false;
      }
    },

    async showDialog(query, segmentId, segmentType) {
      this.query = query;
      this.segmentId = segmentId;
      this.columnsToExport = [];

      this.segmentType = segmentType ? segmentType : 'dynamic';

      this.fetchUserPropertyList();
      this.dialogVisible = true;
    },

    async fetchUserPropertyList() {
      if (this.fetchedUserPropertyList) return;

      try {
        // Fetch customer properties
        let result = await customerServices.fetchUserPropertyList(null, this);
        let list = result.data.data;

        let customProps = _.filter(list, (a) => {
          return a.isCustom == true;
        });
        let systemProps = _.filter(list, (a) => {
          return a.isCustom != true;
        });

        this.userPropertyList = [
          { label: 'Custom Attributes', options: customProps },
          { label: 'System Attributes', options: systemProps }
        ];

        this.fetchedUserPropertyList = true;
      } catch (error) {
        console.error('Failed to fetch customer attrs', error);
      }
    }
  },

  async mounted() {
    this.exportEmailTo = this.$store.state.token.email;
  }
};
