<template>
  <el-dialog title="Export Segment Users" custom-class="createSegmentPopup" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700">
    <div>
      <!-- Email For Export -->
      <div class="p-3 border-b border-gray-200">
        <div class="text-xs text-gray-400">Email</div>
        <div class="text-gray-600">{{ exportEmailTo }}</div>
      </div>

      <!-- Fields To Include In Export -->
      <div class="p-3">
        <div class="text-xs text-gray-400">Customer Attributes To Export</div>
        <el-select class="w-full" autocomplete="off" v-model="columnsToExport" filterable multiple placeholder="Select Attributes To Export">
          <el-option-group v-for="group in userPropertyList" :key="group.label" :label="group.label">
            <el-option v-for="property in group.options" :key="property.columnName" :label="property.name" :value="property.columnName"> </el-option>
          </el-option-group>
        </el-select>
      </div>
    </div>

    <template #footer>
      <el-button type="primary" :loading="exportingSegment" :readOnly="false" @click="exportUsers">Export Users</el-button>
      <el-button type="danger" plain @click="dialogVisible = false">Cancel</el-button>
    </template>
  </el-dialog>
</template>

<style lang="scss" src="./exportSegmentUsersPopup.scss"></style>

<script>
import exportSegmentUsersPopupComponent from './exportSegmentUsersPopupComponent';
export default exportSegmentUsersPopupComponent;
</script>
