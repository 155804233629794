<template>
  <div id="StaticSegmentDetailPage">
    <!-- Loader -->
    <div class="loadingDiv" v-if="fetchingSegmentDetails" v-loading="true" style="min-height: 300px; width: 100%"></div>

    <!-- Page Header -->
    <el-card class="pageHeader" v-if="!fetchingSegmentDetails" shadow="never">
      <div slot="header">
        <div class="headerText">
          <router-link class="text-gray-700" :to="getBackButtonLink()">
            <i class="el-icon-back"></i>
          </router-link>

          &nbsp;&nbsp; Static List - {{ segmentDetails.name }}
        </div>

        <!-- Action Button -->
        <div class="actionBtn">
          <span class="actionBtnDivision">
            <el-button @click="onExportSegmentUsers" size="small" icon="el-icon-message" type="primary" :loading="exportInProgress" plain>Export Customers</el-button>
            <el-tooltip class="item" effect="dark" content="Delete" placement="bottom">
              <el-button size="mini" type="danger" icon="el-icon-delete" circle plain class="deleteBtn" @click="deleteSegment"></el-button>
            </el-tooltip>
          </span>
        </div>
      </div>

      <div class="segmentSizeContainer" v-loading="fetchingSegmentSize">
        <el-row v-if="!fetchingSegmentSize">
          <el-col :span="6" class="columnItem borderRight">
            <div class="percentage">&nbsp;</div>
            <div class="value">
              {{ getNumberWithComma(segmentSize.total) }}
            </div>
            <div class="label">TOTAL USERS</div>
          </el-col>
          <el-col :span="6" class="columnItem borderRight">
            <div class="percentage">
              {{ segmentSize.segmentPerc + '%' }}
            </div>
            <div class="value">
              {{ getNumberWithComma(segmentSize.segmentTotal) }}
            </div>
            <div class="label">Segment USERS</div>
          </el-col>
          <el-col :span="6" class="columnItem borderRight">
            <div class="percentage">
              {{ segmentSize.segmentRegisteredPerc + '%' }}
            </div>
            <div class="value">
              {{ getNumberWithComma(segmentSize.segmentRegistered) }}
            </div>
            <div class="label">Segment Registered Users</div>
          </el-col>
          <el-col :span="6" class="columnItem">
            <div class="percentage">
              {{ segmentSize.segmentAnonymousPerc + '%' }}
            </div>
            <div class="value">
              {{ getNumberWithComma(segmentSize.segmentAnonymous) }}
            </div>
            <div class="label">Segment Anonymous Users</div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card shadow="never" class="box-card channelReachablityCard" v-if="reachablity">
      <div class="cardHeader" slot="header">
        <span>Channel Reachablity</span>
      </div>

      <el-table :data="reachablity" style="width: 100%">
        <el-table-column prop="name" label="Channel" width="230"> </el-table-column>
        <el-table-column prop="count" label="Segment Reachablity #"> </el-table-column>
        <el-table-column prop="percentage" label="Segment Reachablity %"> </el-table-column>
      </el-table>
    </el-card>

    <ExportSegmentUsersPopup ref="exportSegmentUsersPopup"></ExportSegmentUsersPopup>
  </div>
</template>
<style lang="scss" src="./staticSegmentDetail.scss"></style>

<script>
import staticSegmentDetailComponent from './staticSegmentDetailComponent';
export default staticSegmentDetailComponent;
</script>
