import customerModule from '@/services/customer';
import staticSegmentModule from '@/services/staticSegment';
var FileSaver = require('file-saver');
import ExportSegmentUsersPopup from '@/pages/segmentation/exportSegmentUsersPopup/exportSegmentUsersPopup';

export default {
  name: 'staticSegmentDetailComponent',
  components: {
    ExportSegmentUsersPopup
  },
  data() {
    return {
      staticListId: null,
      exportInProgress: false,

      // Segment Details
      segmentDetails: null,
      fetchingSegmentDetails: true,

      //segmentSizeVariables
      fetchingSegmentSize: true,
      segmentSize: null,

      // Reachablity Data
      reachablity: null
    };
  },

  methods: {
    getBackButtonLink() {
      return '/segment/static-list';
    },

    //#region ********************** Static Segment Fetch Data *********

    async getStaticSegmentDetail() {
      try {
        this.fetchingSegmentDetails = true;

        let result = await staticSegmentModule.getStaticSegmentDetail(this.staticListId);

        if (result.data == null) {
          this.$router.replace('/404');
          return;
        }
        this.segmentDetails = result.data;
        this.segmentDetails.data = null;
        this.fetchingSegmentDetails = false;
      } catch (e) {
        this.fetchingSegmentDetails = false;
        this.errorToast('Failed to fetch segment details. Please contact support.');
        this.reportError(e);
      }
    },

    // Segment Size Fetch
    async fetchStaticSegmentSize() {
      try {
        this.fetchingSegmentSize = true;

        let result = await staticSegmentModule.getStaticSegmentSize(this.staticListId);
        this.segmentSize = result.data;
        this.fetchingSegmentSize = false;
      } catch (e) {
        this.errorToast('Failed to fetch segment size details. Please contact support.');
        this.reportError(e);
        this.fetchingSegmentSize = false;
      }
    },

    // Segment Reachablity Fetch
    async fetchStaticSegmentReachablity() {
      try {
        // Wait till segment size data arrived.
        if (!this.segmentSize) {
          setTimeout(() => {
            this.fetchStaticSegmentReachablity();
          }, 200);
          return;
        }

        let result = await staticSegmentModule.getStaticSegmentReachablity(this.staticListId);
        if (result.data == null) {
          this.$router.replace('/404');
          return;
        }
        let response = result.data;

        // Iterate and calculate percentage reachablity
        this.reachablity = [
          { key: 'mobilePush', name: 'Mobile Push' },
          { key: 'inApp', name: 'Mobile In-App' },
          { key: 'sms', name: 'SMS' },
          { key: 'webPush', name: 'Web Push' },
          { key: 'email', name: 'Email' },
          { key: 'whatsApp', name: 'WhatsApp' }
        ];
        // Calculate percentage
        for (let i = 0; i < this.reachablity.length; i++) {
          let key = this.reachablity[i].key;
          let percentage = 0;
          if (this.segmentSize.segmentTotal && this.segmentSize.segmentTotal != 0) {
            percentage = parseInt((100 * response[key]) / this.segmentSize.segmentTotal) + '%';
          }

          this.reachablity[i].percentage = percentage;
          this.reachablity[i].count = response[key];
        }

        this.fetchingSegmentSize = false;
      } catch (e) {
        this.errorToast('Something went wrong.');
        this.reportError(e);
        this.fetchingSegmentSize = false;
      }
    },

    //#endregion ********************** Static Segment Fetch Data ******

    // Delete Segment
    deleteSegment() {
      this.$swal({
        title: `Delete Static List?`,
        text: "Are you sure you want to delete segment? You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(() => {
        setTimeout(async () => {
          try {
            await staticSegmentModule.deleteStaticSegment(this.staticListId, this);

            this.$router.push({
              name: 'StaticList'
            });
            this.successToast(`Static List Deleted.`);
          } catch (e) {
            this.reportError(e);
            this.errorToast(`Something went wrong.`);
            this.deletingCampaign = false;
          }
        }, 50);
      });
    },

    onExportSegmentUsers() {
      this.$refs.exportSegmentUsersPopup.showDialog(null, this.staticListId, 'static');
    },

    async onExportStart() {
      try {
        this.exportInProgress = true;

        let exportCsvContent = null;

        // Parse Segment Filters
        let filter = JSON.parse(JSON.stringify(this.segmentDetails.data));

        var params = {
          filters: filter,
          onlyRegistered: this.onlyRegisteredCustomers
        };

        let result = await customerModule.exportCustomers(params);

        exportCsvContent = result.data;

        //Export csv conent here.
        var blob = new Blob([exportCsvContent], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, 'customers.csv');
        this.$swal('Customers Exported', 'CSV file is downloaded in your local machine.', 'success');
        this.exportInProgress = false;
      } catch (error) {
        this.exportInProgress = false;
        this.errorToast('Something went wrong.');
      }
    }
  },

  async mounted() {
    this.staticListId = this.$route.params.id;
    this.getStaticSegmentDetail();
    this.fetchStaticSegmentSize();
    this.fetchStaticSegmentReachablity();
  }
};
